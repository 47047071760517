<template>
    <div class="burgerMenu" :class="{ 'burgerMenuActive': isBurgerMenuActive }">
        <div class="header">
            <img class="logo" :src="require('@/assets/logo/blackIconOnly.svg')"/>
            <p>Menüü</p>
            <img 
                class="closeXIcon"
                @click="$emit('closeBurgerMenu')"
                :src="require(`@/assets/icons/form/closeX.svg`)" 
                alt=""
            >
        </div>
        <div class="menu">
            <ul class="navMenu">
                <li @click="$emit('closeBurgerMenu')">
                    <RouterLink :to="Tr.i18nRoute({ name: 'home' })">{{ $t("nav.home") }}</RouterLink>
                </li>
                <li @click="$emit('closeBurgerMenu')">
                    <RouterLink :to="Tr.i18nRoute({ name: 'products', params: { productUrl: $t(`router.defaultProduct`) } })">{{ $t("nav.products") }}</RouterLink>
                </li>
                <li @click="$emit('closeBurgerMenu')">
                    <RouterLink :to="Tr.i18nRoute({ name: 'references' })">{{ $t("nav.references") }}</RouterLink>
                </li>
                <li @click="$emit('closeBurgerMenu')">
                    <RouterLink :to="Tr.i18nRoute({ name: 'about' })">{{ $t("nav.about") }}</RouterLink>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Tr from "@/i18n/translation"

export default {
    name: 'burgerMenu',
    props: {
        isBurgerMenuActive: null
    },
    setup() {
        return { Tr } 
    },
    methods: {
        
    }
}
</script>

<style scoped>
.burgerMenu {
    position: absolute;
    top: 0;
    left: -350px;
    z-index: 100;
    width: 300px;
    height: 400px;
    background: white;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    border-radius: 0 0px 10px 0px;
    border-right: 1px solid rgb(220,220,220);
    border-bottom: 1px solid rgb(220,220,220);
}
.burgerMenuActive {
    left: 0;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.header {
    padding-top: 10px;
    padding-bottom: 5px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: rgb(245, 245, 245);
    border-bottom: 1px solid rgb(240,240,240);
}
.header p {
    width: 100%;
    font-size: 28px;
    margin-top: auto;
    margin-top: 10px;
}
.logo {
    margin-top: -4px;
    margin-left: 15px;
    width: 50px;
    height: 50px;
}
.closeXIcon {
    cursor: pointer;
    margin-top: 19px;
    margin-right: 15px;
    width: 20px;
    height: 20px;
}
.navMenu {
    width: 100%;
    margin-top: 25px;
}
ul, li, a {
    color: rgb(50,50,50);
    text-decoration: none;
    list-style-type: none;
    padding: 0;
}
li {
    text-align: start;
    font-size: 22px;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(240,240,240);
    cursor: pointer;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
li:hover {
    width: 95%;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
</style>
