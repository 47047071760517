<template>
    <div class="contactPopup" :class="{ 'contactPopupActive': isContactPopupActive }">
        <span class="error" :class="{ 'errorActive': errorMessage }">{{ errorMessage }}</span>
        <img 
            class="closeXIcon"
            @click="closePopup"
            :src="require(`@/assets/icons/form/closeX.svg`)" 
            alt=""
        >
        <div class="inputFields" v-if="!inquirySent">
            <div class="singleInput">
                <img class="inputIcon" :src="require('@/assets/icons/form/person.svg')"/>
                <input type="text" @click="personError = false; errorMessage = null" :class="{ 'inputError':  personError}" :placeholder="$t(`form.name`)" v-model="person">
            </div>

            <div class="singleInput">
                <img class="inputIcon" :src="require('@/assets/icons/form/business.svg')"/>
                <input type="text" @click="businessError = false; errorMessage = null" :class="{ 'inputError':  businessError}" :placeholder="$t(`form.business`)" v-model="business">
            </div>

            <div class="singleInput">
                <img class="inputIcon" :src="require('@/assets/icons/form/email.svg')"/>
                <input type="text" @click="emailError = false; errorMessage = null" :class="{ 'inputError':  emailError}" :placeholder="$t(`form.email`)" v-model="email">
            </div>

            <div class="singleInput">
                <img class="inputIcon" :src="require('@/assets/icons/form/phone.svg')"/>
                <input type="text" @click="phoneError = false; errorMessage = null" :class="{ 'inputError':  phoneError}" :placeholder="$t(`form.mobile`)" v-model="phoneNr">
            </div>
            <textarea @click="letterError = false; errorMessage = null" :class="{ 'inputError':  letterError}" :placeholder="$t(`form.message`)" v-model="letter"></textarea>
        </div>
        <div class="successMessage" v-if="inquirySent">
            <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
            </svg>
            <p>{{ $t("form.confirmationText") }}</p>
        </div>
        <div class="btnContainer" v-if="!inquirySent">
            <reCaptcha class="recaptcha" @recaptchaSuccess="recaptchaDone = true" />
            <span class="button" @click="sendInquiry()">{{ $t("buttons.sendMessage") }}</span>
        </div>
    </div>
</template>

<script>
import reCaptcha from '../popups/reCaptcha.vue'
export default {
    name: 'contactPopup',
    components: {
        reCaptcha
    },
    props: {
        isContactPopupActive: null
    },  
    data() {
        return {
            inquirySent: false,

            errorMessage: null,

            recaptchaDone: null,

            person: null,
            personError: false,

            business: null,
            businessError: false,

            email: null,
            emailError: false,

            phoneNr: null,
            phoneError: false,

            letter: null,
            letterError: false
        }
    },  
    methods: {
        sendInquiry() {
            if(!this.person) {
                this.personError = true
                this.errorMessage = 'Nime, emaili ja täpsustava teksti sisestamine on kohustuslik!'
            }
            if(!this.email) {
                this.emailError = true
                this.errorMessage = 'Nime, emaili ja täpsustava teksti sisestamine on kohustuslik!'
            }
            if(!this.letter) {
                this.letterError = true
                this.errorMessage = 'Nime, emaili ja täpsustava teksti sisestamine on kohustuslik!'
            }
            if(!this.recaptchaDone) {
                this.errorMessage = 'Palun täitke reCAPTCHA kinnitus või proovige uuesti!'
            }
            if(!this.errorMessage) {
                fetch('https://parnubetoonelement.ee/mail', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "text": this.letter,
                        "name": this.person,
                        "company": this.business,
                        "phone": this.phoneNr,
                        "email": this.email
                    }),
                })
                .then((res) => {
                    if(res.ok) {
                        this.errorMessage = null
                        this.inquirySent = true
                        this.person = null
                        this.business = null
                        this.email = null
                        this.phoneNr = null
                        this.letter = null

                        setTimeout(() => {
                            this.closePopup()
                        }, 2000)
                    } else {
                        this.errorMessage = 'Midagi läks valesti, palun proovi uuesti!'
                    }
                })

            }
            if(this.errorMessage) {
                setTimeout(() => {
                    this.errorMessage = null
                }, 3000)
            }
        },

        closePopup() {
            this.inquirySent = false
            this.$emit('closePopup')
        }
    },
}
</script>
<style scoped>
.contactPopup {
    z-index: 100000;
    position: fixed;
    top: -2000px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: 450px;
    padding-bottom: 15px;
    width: 750px;
    background: white;
    border-radius: 10px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    overflow: hidden;
}
.contactPopupActive {
    top: 0;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.inputFields {
    padding-left: 75px;
    padding-right: 75px;
    margin: 0 auto;
    margin-top: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.singleInput {
    position: relative;
    width: 45%;
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 0px;
    margin-bottom: 15px;
}
.inputIcon {
    position: absolute;
    margin-left: 0px;
    margin-top: 11px;
    z-index: 20;
    width: 30px;
    opacity: 1;
}
input {
    position: relative;
    z-index: 10;
    height: 50px;
    width: 97%;
    text-indent: 40px;
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid #4d455d7c;
    color: #4D455D;
}
textarea {
    height: 150px;
    margin-top: 10px;
    padding-top: 15px;
    padding-left: 4%;
    padding-right: 4%;
    width: 92%;
    border-radius: 10px;
    border: 1px solid #4d455d7c;
}
input:focus {
    outline: none;
    border-bottom: 1px solid #4D455D;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
textarea:focus {
    outline: none;
    border: 1px solid #4D455D;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
textarea::-webkit-input-placeholder, input::-webkit-input-placeholder {
    font-size: 15px;
    color: #4D455D;
}
textarea::-moz-placeholder, input::-moz-placeholder {
    font-size: 15px;
    color: #4D455D;
}
textarea:-ms-input-placeholder, input:-ms-input-placeholder {
    font-size: 15px;
    color: #4D455D;
}
textarea::-webkit-input-placeholder {
    font-size: 15px;
    color: #4D455D;
}
textarea::-moz-placeholder {
    font-size: 15px;
    color: #4D455D;
}
textarea:-ms-input-placeholder {
    font-size: 15px;
    color: #4D455D;
}
textarea::-ms-input-placeholder {
    font-size: 15px;
    color: #4D455D;
}
textarea::placeholder {
    font-size: 15px;
    color: #4D455D;
}
textarea {
    width: 100%;
    resize: none;
    margin-bottom: 30px;
    color: #4D455D;
}
.inputError::-webkit-input-placeholder {
    color: #a11e34;
    -webkit-transition: .2s;
    transition: .2s;
}
.inputError::-moz-placeholder {
    color: #a11e34;
    -moz-transition: .2s;
    transition: .2s;
}
.inputError:-ms-input-placeholder {
    color: #a11e34;
    -ms-transition: .2s;
    transition: .2s;
}
.inputError::-ms-input-placeholder {
    color: #a11e34;
    -ms-transition: .2s;
    transition: .2s;
}
.inputError::placeholder {
    color: #a11e34;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.inputError {
    border-bottom: 1px solid #E76161;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.error {
    position: absolute;
    z-index: 101;
    top: -100px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 0px 0px 10px 10px;
    width: 80%;
    background: #E76161;
    color: white;
    font-size: 18px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.errorActive {
    top: 0px;
    left: 0;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.successMessage {
    height: 350px;
    position: relative;
    z-index: 100;
}
.successMessage p {
    position: absolute;
    left: 0;
    right: 0;
    top: 300px;
    padding-left: 25px;
    padding-right: 25px;
    margin: auto;
    z-index: 1000;
    margin-top: 0px;
    font-size: 28px;
    color: #64C3A2;
}
.btnContainer {
    margin-top: -20px;
    padding-left: 75px;
    padding-right: 75px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.button {
    border: 1px solid #4D455D;
    background: #4D455D;
    color: white;
    border-radius: 10px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 8px;
    padding-bottom: 8px;
    height: 43px;
    font-size: 20px;
}
.button:hover {
    cursor: pointer;
    opacity: .9;
}
.closeXIcon {
    position: absolute;
    z-index: 1000;
    right: 27px;
    top: 23px;
    width: 18px;
    cursor: pointer;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #64C3A2;
    fill: none;
    -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
            animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
    position: absolute;
    left: 0;
    right: 0;
    top: 75px;
    margin: auto;
    width: 175px;
    height: 175px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #64C3A2;
    stroke-miterlimit: 10;
    -webkit-box-shadow: inset 0px 0px 0px #64C3A2;
            box-shadow: inset 0px 0px 0px #64C3A2;
    -webkit-animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
            animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
    -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
            animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@-webkit-keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}
@-webkit-keyframes scale {
    0%, 100% {
        -webkit-transform: none;
                transform: none;
    }
    50% {
        -webkit-transform: scale3d(1.1, 1.1, 1);
                transform: scale3d(1.1, 1.1, 1);
    }
}
@keyframes scale {
    0%, 100% {
        -webkit-transform: none;
                transform: none;
    }
    50% {
        -webkit-transform: scale3d(1.1, 1.1, 1);
                transform: scale3d(1.1, 1.1, 1);
    }
}
@-webkit-keyframes fill {
    100% {
        -webkit-box-shadow: inset 0px 0px 0px 63px #ffffff;
                box-shadow: inset 0px 0px 0px 63px #ffffff;
    }
}
@keyframes fill {
    100% {
        -webkit-box-shadow: inset 0px 0px 0px 63px #ffffff;
                box-shadow: inset 0px 0px 0px 63px #ffffff;
    }
}
@media (max-width: 800px) {
    .contactPopup {
        width: 90%;
        height: fit-content;
    }
}
@media (max-width: 730px) {
    .button {
        margin-top: 10px;
        padding-left: 50px;
        padding-right: 50px;
    }
}
@media (max-width: 600px) {
    .inputFields {
        padding-left: 15px;
        padding-right: 15px;
        margin: 0 auto;
        margin-top: 50px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }
    .btnContainer {
        margin-top: -20px;
        padding-left: 15px;
        padding-right: 15px;
    }
    .error {
        width: 98%;
        padding-left: 1%;
        padding-right: 1%;
    }
}
@media (max-width: 450px) {
    .singleInput {
        width: 100%;
        margin-bottom: 0;
    }
    .contactPopup {
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding-bottom: 25px;
    }
    .inputFields {
        margin-top: 30px;
    }
    textarea {
        height: 70px;
    }
}
</style>