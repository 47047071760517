<template>
    <div class="referencesCard" :class="{ 'referencesCardSmall': $route.name == 'references' }">
        <div class="wrapper">
            <h2>{{ name }}</h2>
            <div class="dot"></div>
            <p>{{ date }}</p>
        </div>
        <p style="text-align: start; font-size: 13px; color: #a6bdd4; margin-bottom: 5px;">{{ refData.address }}</p>
        <div class="imageContainer">
            <img 
                class="image"
                :src="require(`@/assets/images/references/${image}`)" 
                alt=""
            >
            <div class="smallTitleContainer">
                <img 
                    class="whiteLogo"
                    :src="require('@/assets/logo/whiteLogo.svg')" 
                    alt=""
                >
                <p class="smallTitle">{{ $t("referencesView.elementsTitle") }}</p>
            </div>

            <Button @click="openImages(0)" class="viewImagesBtn" :label="$t('buttons.viewImages')" icon="pi pi-image" severity="secondary" />
        </div>
        <h2 style="font-size: 18px; margin-top: 20px; text-align: start;">{{$t('referencesView.cardTitle')}}</h2>
        <div class="products">
            <Chip class="elementChip" :label="product" icon="pi pi-image" @click="openImages(0)" v-for="product of products" :key="product.id" />
        </div>
        <!-- <Fieldset legend="Meie betoonelemendid" :toggleable="false" style="margin-top: 10px">
        </Fieldset> -->
    </div>
</template>

<script>
import Button from 'primevue/button';
// import Fieldset from 'primevue/fieldset';
import Chip from 'primevue/chip';
import 'photoswipe/style.css';
import PhotoSwipe from 'photoswipe';

export default {
    name: 'referenceCard',
    components: {
        Button,
        // Fieldset,
        Chip,
    },
    props: {
        image: {},
        images: {},
        name: {},
        products: {},
        date: {},
        architect: {},
        refData: {}
    },  
    data() {
        return {

        }
    },  
    methods: {
        openImages(index) {
            let sourceImages = []
            for(const element of this.images) {
                sourceImages.push({
                    src: require(`@/assets/images/references/${element.name}`),
                    width: element.width,
                    height: element.height
                })
            }

            const options = {
                dataSource: sourceImages,
                showHideAnimationType: 'none'
            };

            options.index = index;
            const pswp = new PhotoSwipe(options);
            pswp.init();
        },
    },
}
</script>
<style>
.p-chip-label {
    margin-left: 15px;
}
.p-chip-icon {
    color: white;
}
</style>
<style scoped>
.referencesCard {
    position: relative;
    width: 433px;
    border-radius: 20px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    background: #EFF3F7;
    border: 1px solid rgb(240, 240, 240);
    padding: 15px 25px 20px 25px;
}
.referencesCardSmall {
    width: 582px;
}
.referencesCard:hover {
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.wrapper {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin: 0 auto;
    margin-top: 0px;
}
.wrapper h2 {
    margin: 0;
    text-align: start;
    color: #4D455D;
    font-size: 22px;
}
.dot {
    width: 5px;
    height: 5px;
    margin-top: 17px;
    margin-bottom: auto;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 100%;
    background: rgba(245, 162, 53, 0);
}
.wrapper p {
    position: absolute;
    right: 0;
    top: -0px;
    text-align: center;
    font-size: 38px;
    color: #ccd7e2;
    margin: 0;
}
.imageContainer {
    position: relative;
    height: 300px;
    overflow: hidden;
    border-radius: 15px;
    margin-top: 10px;
}
.viewImagesBtn {
    position: absolute;
    bottom: 10px;
    right: 12px;
    height: 33px;
}
.image {
    margin-top: 0px;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
}
span {
    position: absolute;
    top: 5px;
    right: 5px;
    color: rgb(220, 220, 220);
    font-size: 12px;
}

.products {
    position: relative;
    width: 100%;
    z-index: 10;
    text-align: start;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.products {
    padding-top: 8px;
    margin-bottom: 10px;
    width: 100%;
}
.elementChip {
    width: 100%; 
    margin-bottom: 6px; 
    margin-top: 6px; 
    height: 50px; 
    padding-left: 24px; 
    cursor: pointer;
    background: rgb(255, 255, 255);
    border: 1px solid rgb(210,210,210);
    color: rgb(62, 78, 85);
    border-radius: 50px;
    font-family: 'Lato', sans-serif;
    font-size: 17px;
}
.elementChip:hover {
    background: white;
    border: 1px solid rgb(100,100,100);
}
.smallTitleContainer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: start;
    position: absolute;
    top: 0px;
    left: 0px;
    padding-top: 8px;
    padding-left: 15px;
    padding-bottom: 40px;
    width: 100%;
    background: -webkit-gradient(linear, left top, left bottom, from(rgb(22, 47, 77)), to(transparent));
    background: -o-linear-gradient(top, rgb(22, 47, 77), transparent);
    background: linear-gradient(to bottom, rgb(22, 47, 77), transparent);
}
.whiteLogo {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    opacity: .9;
    width: 33px;
    height: 33px;
}
.smallTitle {
    padding: 0;
    margin: 0;
    padding-top: 10px;
    font-weight: bold;
    color: #ffffff;
    font-size: 18px;
}
.singleProduct {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 10px 20px 10px 15px;
    border: 1px solid rgb(235,235,235);
    background: rgb(255,255,255);
    border-radius: 115px;
    margin-bottom: 10px;
}
/* .singleProduct:nth-child(2) {
    margin-left: 20px;
}
.singleProduct:nth-child(5) {
    margin-left: 20px;
}
.singleProduct:not(:nth-child(4), :nth-child(7)) {
    margin-right: 15px;
} */
.singleProduct p {
    font-size: 18px;
}
.singleProduct:hover {
    cursor: pointer;
    color: #2a4249;
    opacity: 1;
    background: rgb(250,250,250);
}
.icon {
    width: 20px;
    margin-right: 8px;
}
@media (max-width: 1350px) {
    .referencesCard {
        width: 49%;
    }
}
@media (max-width: 950px) {
    .referencesCard {
        width: 95%;
        margin: 0 auto;
    }
}
@media (max-width: 500px) {
    .referencesCard {
        width: 100%;
        margin: 0 auto;
        padding: 15px 15px 20px 15px;
        border-radius: 0;
    }

    .wrapper p {
        z-index: 10;
        top: 70px;
        right: 10px;
        font-size: 35px;
        color: white;
    }
    .smallTitle {
        display: none;
    }
}
</style>