<template>
    <div class="imageCarousel">
        <img 
            class="arrow"
            @click="currentSlide --"
            :src="require('@/assets/icons/smallArrow.svg')" 
            alt=""
        >
        <img 
            class="arrow"
            @click="currentSlide ++"
            :src="require('@/assets/icons/smallArrow.svg')" 
            alt=""
        >
        <Carousel id="gallery" :style="{height: carouselHeight}" :items-to-show="1" :wrap-around="true" v-model="currentSlide">
            <Slide v-for="slide in slides" :key="slide">
                <img 
                    class="singleSlide"
                    :style="{height: carouselHeight}"
                    :src="require(`@/assets/images/products/${imgAssetDir}/${slide.name}`)" 
                    alt=""
                >
            </Slide>
        </Carousel>


        <Carousel
            id="thumbnails"
            :items-to-show="4"
            :wrap-around="true"
            v-model="currentSlide"
            ref="carousel"
        >
            <Slide v-for="(slide, index) in slides" :key="slide">
                <img 
                    class="smallSlide"
                    :style="{height: thumbsHeight}"
                    @click="slideTo(index)"
                    :src="require(`@/assets/images/products/${imgAssetDir}/${slide.name}`)" 
                    alt=""
                >
            </Slide>
        </Carousel>
    </div>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default {
    name: 'imageCarousel',
    components: {
        Carousel,
        Slide,
    },
    props: {
        slides: {},
        productUrl: String,
        imgAssetDir: {},
        carouselHeight: {
            default: '290px'
        },
        thumbsHeight: {
            default: '75px'
        }
    },    
    data() {
        return {
            currentSlide: 0,
        }
    },  
    methods: {
        slideTo(val) {
            this.currentSlide = val
        },
    },
}
</script>
<style scoped>
.imageCarousel {
    position: relative;
    width: 100%;
}
.singleSlide {
    height: 290px;
    margin-top: 5px;
    margin-bottom: 5px;
    width: 97%;
    margin: 5px auto;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.4);
}
#gallery {
    width: 100%;
}
#thumbnails {
    width: 97%;
    margin: 0 auto;
    margin-top: 25px;
}
.smallSlide {
    height: 75px;
    width: 95%;
    border: 1px solid rgb(220,220,220);
    border-radius: 10px;
    cursor: pointer;
    object-fit: cover;
}
.arrow {
    position: absolute;
    left: -50px;
    top: 0;
    bottom: 80px;
    margin: auto;
    width: 25px;
    height: 25px;
    padding: 6px;
    cursor: pointer;
    background: rgb(255,255,255);
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,.3);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,.3);
    border-radius: 100%;
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
}
.arrow:nth-child(2) {
    right: -50px;
    left: auto;
    -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
            transform: rotate(0deg);
}
@media (max-width: 625px) {
    .arrow {
        left: -12px;
        z-index: 10;
    }
    .arrow:nth-child(2) {
        right: -12px;
        z-index: 10;
    }
}
</style>