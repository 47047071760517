<template>
    <div class="wrapper">
        <img 
            class="logoImg"
            @click="$router.push('/')"
            :src="require('@/assets/logo/logoSVG.svg')" 
            alt=""
        >
        <img class="logo" :src="require('@/assets/logo/blackIconOnly.svg')"/>
        <p>Pärnu Betoonelement</p>
        <button @click="$emit('openContactPopup')">{{ $t(`buttons.contactUs`) }}</button>
    </div>
</template>

<script>

export default {
    name: 'mobileBar',
    methods: {
        
    }
}
</script>

<style scoped>
.wrapper  {
    position: fixed;
    z-index: 1000;
    display: none;
    bottom: 0;
    height: 65px;
    width: 100%;
    background: white;
    -webkit-box-shadow: 0px -1px 4px -1px rgba(0,0,0,0.2);
    box-shadow: 0px -1px 4px -1px rgba(0,0,0,0.2);
}
.logoImg {
    display: none;
}
.logo {
    width: 50px;
    height: 62px;
    margin-right: 10px;
    margin-left: 25px;
}
p { 
    position: absolute;
    font-size: 28px;
    top: 13px;
    bottom: 0;
    left: 90px;
    margin: auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
button {
    position: absolute;
    right: 15px;
    top: 2px;
    bottom: 0;
    margin: auto;
    height: 40px;
    width: 220px;
    z-index: 5;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    font-family: 'DM sans', sans-serif;
    padding-left: 30px;
    padding-right: 30px;
    background: rgb(66, 74, 95);
    border-radius: 70px;
    border: none;
    -webkit-transition: background .4s ease-out;  
    -o-transition: background .4s ease-out;  
    transition: background .4s ease-out;  
}
button:hover {
    /* background: rgb(252,70,107);
    background: linear-gradient(45deg, rgba(252,70,107,1) 0%, rgba(121,2,82,1) 100%);  */
    -webkit-transition: background .4s ease-out;  
    -o-transition: background .4s ease-out;  
    transition: background .4s ease-out;  
    background-position: 179px; 
    cursor: pointer;
    opacity: .9;
}
@media (max-width: 800px) {
    .wrapper {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }
}
@media (max-width: 630px) {
    button {
        width: 150px;
        padding: 0;
    }
    .logo {
        margin-left: 15px;
    }
    p {
        line-height: 20px;
        width: 180px;
        top: 0;
        left: 75px;
        font-size: 20px;
        text-align: start;
    }
}
@media (max-width: 390px) {
    button {
        width: 130px;
        padding: 0;
    }
    .logo {
        width: 45px;
    }
    p {
        line-height: 20px;
        width: 160px;
        top: 0;
        left: 70px;
        font-size: 18px;
        text-align: start;
    }
}
</style>
