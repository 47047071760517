<template>
    <body :class="{ 'bodyNoPadding':  $route.name == 'home', 'bodyPadding': $route.name != 'home'}">
        <div v-if="isPageLoading">
            <loadingScreen />
        </div>
        <div v-else>
            <staticMenu 
                v-if="$route.name == 'home'" 
                @localeChanged="localeChanged ++"
                @activateBurgerMenu="isBurgerMenuActive = true"
            />
            <fixedMenu 
                :scrollPosition="scrollPosition" 
                :isContactPopupActive="isContactPopupActive"
                @openContactPopup="openContactPopup"
                :class="{ 
                    'fixedMenuActive': scrollPosition >= scrollPosToFixed, 
                    'fixedMenu':scrollPosition <= scrollPosToFixed,
                    'staticMenu': scrollPosToFixed == -1
                }" 
                @localeChanged="localeChanged ++"
            />
            <burgerMenu
                @closeBurgerMenu="isBurgerMenuActive = false"
                :isBurgerMenuActive="isBurgerMenuActive" 
            />
            <router-view 
                v-if="productList"
                :localeChanged="localeChanged"
                @closeBurgerMenu="isBurgerMenuActive = false"
                @openProduct="openProduct"
                @openContactPopup="openContactPopup"
                :productList="productList"
                :references="references"
                :textSections="textSections"
            />
            <productPopup 
                ref="productPopupComp"
                :productData="productData"
                :isProductPopupActive="isProductPopupActive"
                @closePopup="closePopup"
                @openContactPopup="openContactPopup"
            />
            <contactPopup 
                v-if="$route.name != 'about'" 
                ref="contactPopupComp"
                @closePopup="closeContactPopup" 
                :isContactPopupActive="isContactPopupActive"
            />
            <cookieConsent />
            <mainFooter @localeChanged="localeChanged ++" />
            <mobileBar v-if="$route.name != 'about'" @openContactPopup="openContactPopup" />
        </div>
        <div 
            class="darkBackground" 
            :class="{'darkBackgroundActive': isProductPopupActive || isContactPopupActive}"
            v-if="isBackgroundActive"
            @click="closePopup(); closeContactPopup()"
        ></div>
    </body>
</template>

<script>
import staticMenu from "@/components/menus/staticMenu.vue"
import fixedMenu from "@/components/menus/fixedMenu.vue"
import burgerMenu from "@/components/menus/burger.vue"
import mobileBar from "@/components/menus/mobileBar.vue"
import mainFooter from "@/components/menus/footer.vue"
import productPopup from "@/components/product/popup.vue"
import contactPopup from "@/components/contact/popup.vue"

import cookieConsent from "@/components/popups/cookieConsent.vue"

import loadingScreen from "@/components/home/loadingScreen.vue"

import { onMounted, onUnmounted, ref } from 'vue'
import tr from "@/i18n/translation"
export default {
    
    name: 'App',
    components: {
        staticMenu,
        fixedMenu,
        burgerMenu,
        mobileBar,
        mainFooter,
        productPopup,
        contactPopup,
        cookieConsent,
        loadingScreen
    },
    created() {
        this.getLocaleData()
        this.$router.beforeEach((to, from, next) => {
            if(to.name != 'home') {
                this.scrollPosToFixed = -1
            } else {
                this.scrollPosToFixed = 330
            }
            next()
        })
        setTimeout(() => {
            this.isPageLoading = false
        }, 2400)
    },
    setup() {
        const scrollPosition = ref(0)

        const handleScroll = () => {
            scrollPosition.value = window.scrollY
        }

        onMounted(() => {
            window.addEventListener('scroll', handleScroll)
        })

        onUnmounted(() => {
            window.removeEventListener('scroll', handleScroll)
        })

        return { scrollPosition }
    },
    watch: {
        localeChanged() {
            this.getLocaleData()
        }
    },
    data() {
        return {
            isPageLoading: true,
            localeChanged: 0,
            isBurgerMenuActive: false,
            isProductPopupActive: false,
            isContactPopupActive: false,
            isBackgroundActive: false,
            productData: null,
            scrollPosToFixed: 330,

            productList: null,
            references: null,
            textSections: null,
        }
    },
    methods: {
        getLocaleData() {
            const promise = tr.productList()
            promise.then((result) => {
                this.productList = result.productList
                this.references = result.references
                this.textSections = result.textSections
            })
        },

        openContactPopup() {
            this.isBackgroundActive = true

            setTimeout(() => {
                this.isContactPopupActive = true
            }, 100);

        },

        closeContactPopup() {
            this.isContactPopupActive = false
            this.isBackgroundActive = false
            this.$refs.contactPopupComp.inquirySent = false
        },

        openProduct(product) {
            this.$gtag('event', 'Tooted', {
                Toodete_vaatamised: `Avati toode - ${product.name}`,
            })
            var scrollbarWidth = window.innerWidth - document.documentElement.clientWidth
            document.body.style.paddingRight = scrollbarWidth + 'px'
            document.documentElement.style.overflow = 'hidden'
            this.isBackgroundActive = true

            setTimeout(() => {
                this.isProductPopupActive = true
            }, 100);

            this.productData = product
        },

        closePopup() {
            document.body.style.paddingRight = 0 + 'px'
            document.documentElement.style.overflow = 'auto'

            this.isProductPopupActive = false
            this.$refs.productPopupComp.isBackgroundActive = false
            setTimeout(() => {
                this.isBackgroundActive = false
            }, 200);
        }
    }
}
</script>

<style>
@font-face {
  font-family: 'DM sans';
  src: url('../public/fonts/DMSans-Regular.ttf');
}

@font-face {
  font-family: 'Lato';
  src: url('../public/fonts/Lato-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans';
  src: url('../public/fonts/OpenSans-Regular.ttf');
}

body {
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    background: white;
}
input {
    background: white;
}
textarea {
    background: white;
}
.bodyNoPadding {
    padding-top: 0;
}
.bodyPadding {
    padding-top: 70px;
}
.fixedMenu {
    position: fixed;
    top: -800px;
}
.fixedMenuActive {
    position: fixed;
    z-index: 1000;
    top: 00px;
    -webkit-transition: .7s;
    -o-transition: .7s;
    transition: .7s;
    /* -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.3); */
}
.staticMenu {
    position: absolute;
    top: 0;
}
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
p, span, a, input, textarea {
    margin: 0;
    font-family: 'DM sans', sans-serif;
}
h2 {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
}
button {
    font-family: 'Lato', sans-serif;
}
.darkBackground {
    position: fixed;
    z-index: 99999;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.darkBackgroundActive {
    z-index: 99999;
    background: rgba(0, 0, 0, 0.425);
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}

@media (max-width: 800px) {
    body {
        padding-bottom: 35px;
    }
}
</style>
