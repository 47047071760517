<template>
    <div class="homeView">
        <mainBanner
            @click="$emit('closeBurgerMenu')"
            @openContactPopup="$emit('openContactPopup')" 
            @scrollToProducts="scrollToProducts()"
        />
        <!-- <h2>{{ $t("home.header") }}</h2> -->
        <!-- <div class="roundedDivider"></div> -->
        <textSections
            style="position: relative; z-index: 10;"
            v-if="textSections"
            :textSections="textSections"
        />
        
        <div class="productSection">
            <img 
                class="illustration"
                :src="require('@/assets/illustrations/drawings/2115992.svg')" 
                alt=""
            >
            <div class="titleWrapper">
                <h2 class="leftAlignedTitled" id="productsSector">{{ $t("home.header2") }}</h2>
                <div class="roundedDivider" id="roundedDividerLeft"></div>
            </div>
    
            <div class="products">
                <productCard 
                    class="productCard"
                    @click="openProduct(product)"
                    v-for="product of productList" :key="product.id"
                    :image="product.coverImage"
                    :name="product.name"
                    :category="product.category"
                    :button="product.button"
                    :productUrl="product.productUrl"
                    :shortDescription="product.shortDescription"
                    :imgAssetDir="product.imgAssetDir"
                />
            </div>
        </div>
        
        <div class="titleWrapper">
            <h2 class="leftAlignedTitled" id="refSector">{{ $t("home.header3") }}</h2>
            <div class="roundedDivider" id="roundedDividerLeft"></div>
        </div>

        <div class="references">
            <referenceCard 
                class="referencesCard"
                @openProduct='product'
                v-for="reference of references" :key="reference.id"
                :refData="reference"
                :images="reference.refImages"
                :image="reference.coverImage"
                :refImages="reference.refImages"
                :name="reference.name"
                :architect="reference.architect"
                :date="reference.date"
                :products="reference.products"
            />
        </div>
        
        <div class="titleWrapper" style="width: 100%">
            <!-- <h2 class="leftAlignedTitled" id="partnersTitle">{{ $t("home.header4") }}</h2>
            <div class="roundedDividerSmall"></div> -->
            <div class="logos" v-if="partners">
                <Carousel
                    id="carousel"
                    :slidesCount="9"
                    :slideWidth="200"
                    :itemsToShow="itemsToShow"
                    :wrap-around="true"
                    :autoplay="1000"
                    :transition="3000"
                    :pauseAutoplayOnHover="true"
                    snapAlign="start"
                    v-model="currentSilde"
                    ref="carousel"
                >
                    <Slide id="slide" v-for="slide of partners" :key="slide.id" @click="openPartnerLink(slide.homePageUrl)">
                        <img 
                            class="partnerLogo"
                            :src="require(`@/assets/images/partners/${slide.logoImg}`)" 
                            alt=""
                        >
                    </Slide>
                </Carousel>
            </div>
        </div>
    </div>
</template>

<script>
import mainBanner from "@/components/home/banner.vue"
import textSections from "@/components/home/textSections.vue"
import productCard from "@/components/product/card.vue"
import referenceCard from "@/components/references/card.vue"
import tr from "@/i18n/translation"

import { Carousel, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default {
    name: 'homeView',
    props: {
        localeChanged: {},
        productList: null,
        references: null,
        textSections: null,
    },
    components: {
        mainBanner,
        productCard,
        referenceCard,
        textSections,
        Carousel,
        Slide,
    },
    data() {
        return {
            currentSilde: 0,
            itemsToShow: 5,
            partners: null
        }
    },
    methods: {
        openPartnerLink(link) {
            const newTab = window.open(link, '_blank');
            newTab.focus();
        },

        scrollToProducts() {
            document.getElementById("productsSector").scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            });
        },

        getLocaleData() {
            const promise = tr.productList()
            promise.then((result) => {
                this.partners = result.partners
            })
        },

        product(productName) {
            for(const element of this.productList) {
                if(element.name == productName) {
                    this.openProduct(element)
                } 
            }
        },

        openProduct(product) {
            this.$emit('openProduct', product)
        },

        handleResize() {
            let width = window.innerWidth
            if(width < 1350) {
                this.itemsToShow = 4
            }
            if(width < 1200) {
                this.itemsToShow = 3
            }
            if(width < 470) {
                this.itemsToShow = 2
            }
            if(width > 470) {
                this.itemsToShow = 3
            }
            if(width < 375) {
                this.itemsToShow = 1
            }
            if(width > 375) {
                this.itemsToShow = 2
            }
            if(width > 1200) {
                this.itemsToShow = 4
            }
            if(width > 1350) {
                this.itemsToShow = 5
            }
        }
    },
    created() {
        this.getLocaleData()
        this.handleResize()

        this.$gtag('event', 'Lehe vaatamised', {
            Leheküljed: 'Avaleht',
        })
    },
    mounted() {
        window.addEventListener("resize", this.handleResize);
    },
    unmounted() {
        window.removeEventListener("resize", this.handleResize);
    },
}
</script>
<style scoped>
.titleWrapper {
    width: 1350px;
    margin: 0 auto;
}
h2 {
    position: relative;
    margin-top: 0px;
    font-size: 40px;
    color: #312d38;
    z-index: 10;
}
.leftAlignedTitled {
    text-align: start;
}
.roundedDivider {
    position: relative;
    width: 120px;
    height: 4px;
    border-radius: 4px;
    margin: 0 auto;
    margin-top: 15px;
    margin-bottom: 50px;
    background: #4A154B;
    z-index: 10;
}
#roundedDividerLeft {
    margin-left: 0px;
}
.references {
    width: 1350px;
    margin: 0 auto;
    margin-bottom: 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-column-gap: 25px;
       -moz-column-gap: 25px;
            column-gap: 25px;
    row-gap: 25px;
}
.referencesCard {
    margin-bottom: 50px;
}
.productSection {
    position: relative;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 100px;
    margin-bottom: 20px;
    background: #eff3f7;
}
.illustration {
    position: absolute;
    width: 100%;
    height: 300px;
    right: 0;
    bottom: -100px;
    opacity: .8;
    -o-object-fit: cover;
       object-fit: cover;
}
.products {
    width: 1350px;
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-column-gap: 16px;
       -moz-column-gap: 16px;
            column-gap: 16px;
    row-gap: 25px;
}
#carousel {
    width: 100%;
}
#slide {
    height: 160px;
    border: 1px solid transparent;
    border-radius: 5px;
}
#slide:hover {
    cursor: pointer;
}
.logos {
    position: relative;
    padding-top: 20px;
    width: 100%;
    margin-bottom: 30px;
}
#partnersTitle {
    margin-top: 0px;
    font-size: 32px;
}
.partnerLogo {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 280px;
}
.roundedDividerSmall {
    margin-top: 10px;
    width: 120px;
    height: 3px;
    border-radius: 4px;
    background: #4A154B;
}
@media (max-width: 1350px) {
    .titleWrapper {
        width: 100%;
    }
    .references {
        width: 96%;
        margin-left: 25px;
    }
    .products {
        width: 96%;
        margin-left: 25px;
    }
    .logos {
        width: 96%;
        margin-left: 25px;
    }
    h2 {
        padding-left: 15px;
        padding-right: 15px;
    }
    #roundedDividerLeft {
        margin-left: 25px;
    }
    .roundedDividerSmall {
        margin-left: 20px;
    }
}
@media (max-width: 1200px) {
    .partnerLogo {
        width: 100%;
    }
}
@media (max-width: 950px) {
    .references {
        width: 100%;
        margin-left: 0px;
    }
    .referencesCard {
        margin-bottom: 25px;
    }
}
@media (max-width: 830px) {
    .productSection {
        padding-top: 75px;
    }
    #productsSector {
        margin-top: -50px;
        text-align: center;
    }
    #refSector {
        margin-top: -10px;
        text-align: center;
    }
    #roundedDividerLeft {
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 25px;
    }
    .productCard {
        margin-bottom: 25px;
    }
    .products {
        width: 100%;
        margin-left: 25px;
    }
}
@media (max-width: 550px) {
    .titleWrapper h2 {
        font-size: 30px;
    }
    #partnersTitle {
        font-size: 30px;
        text-align: center;
    }
    .roundedDividerSmall {
        margin: 0px auto;
        margin-top: 15px;
    }
}
@media (max-width: 470px) {
    .logos {
        width: 90%;
        padding-top: 20px;
        margin: 0 auto;
        margin-bottom: 25px;
    }
}
</style>
