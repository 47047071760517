import Tr from "@/i18n/translation"

import home from '../views/homeView.vue'
import about from '../views/aboutView.vue'
import products from '../views/productView.vue'
import references from '../views/referencesView.vue'
import privacyPolicy from '../views/privacyPolicy.vue'

import { RouterView } from 'vue-router'

const some = {
    decideInitialRoutes(locale) {
        switch (locale) {
            case 'et': {
                return [
                    {
                        name: "mainET",
                        path: "/:locale?",
                        component: RouterView,
                        beforeEnter: Tr.routeMiddleware,
                        children: [
                            {
                                path: "",
                                name: "home",
                                component: home
                            },
                            {
                                path: "meist",
                                name: "about",
                                component: about
                            },
                            {
                                path: "tooted/:productUrl",
                                name: "products",
                                component: products
                            },
                            {
                                path: "referentsid",
                                name: "references",
                                component: references
                            },
                            {
                                path: "privaatsustingimused",
                                name: "privacyPolicy",
                                component: privacyPolicy
                            }
                        ]
                    }
                ];
            }
            case 'en': {
                return [
                    {
                        name: "mainEN",
                        path: "/:locale?",
                        component: RouterView,
                        beforeEnter: Tr.routeMiddleware,
                        children: [
                            {
                                path: "",
                                name: "home",
                                component: home
                            },
                            {
                                path: "meist",
                                name: "about",
                                component: about
                            },
                            {
                                path: "products/:productUrl",
                                name: "products",
                                component: products
                            },
                            {
                                path: "referentsid",
                                name: "references",
                                component: references
                            },
                            {
                                path: "privacy-policy",
                                name: "privacyPolicy",
                                component: privacyPolicy
                            }
                        ]
                    }
                ];
            }
        }
    }
}


export default some