import { createI18n } from "vue-i18n";
// import en from "./locales/en.json"
import et from "./locales/et.json"
import en from "./locales/en.json"

export default createI18n({
    locale: process.env.VUE_APP_DEFAULT_LOCALE,
    fallbackLocale: process.env.VUE_APP_FALLBACK_LOCALE,
    legacy: false,
    globalInjection: true,
    messages: {
        et, en
    }
})