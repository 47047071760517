<template>
    <div>
        <div class="content">
            <div class="data">
                <div class="single">
                    <img class="logo" :src="require('@/assets/logo/blackIconOnly.svg')"/>
                    <h2>{{ $t("companyData.name") }} OÜ</h2>
                </div>
                
                <p><b>{{ $t("about.regNrTitle") }}</b>: {{ $t("companyData.regNr") }}</p>
                <p><b>{{ $t("about.ibanTitle") }}</b>: {{ $t("companyData.iban") }}</p>
                <p><b>{{ $t("about.officeTitle") }}</b>: {{ $t("companyData.office") }}</p>

                <div class="nameWrapper">
                    <img class="logo" :src="require('@/assets/logo/blackIconOnly.svg')"/>
                    <h2>{{ $t("about.dataTitleTwo") }}</h2>
                </div>
                <div class="singleData">
                    <img class="dataIcon" :src="require('@/assets/icons/dark/location.svg')"/>
                    <p> {{ $t("companyData.address") }}</p>
                </div>
                <div class="singleData">
                    <img class="dataIcon" :src="require('@/assets/icons/dark/phone.svg')"/>
                    <p> {{ $t("companyData.mobileNr") }}</p>
                </div>
                <div class="singleData">
                    <img class="dataIcon" :src="require('@/assets/icons/dark/email.svg')"/>
                    <a class="email" href="mailto:info@parnubetoonelement.ee" style="color: steelblue"> info@parnubetoonelement.ee</a>
                </div>
            </div>
            <div class="form">
                <p class="error" :class="{ 'errorActive': errorMessage }">{{ errorMessage }}</p>
                <div class="singleInput">
                    <img class="inputIcon" :src="require('@/assets/icons/form/person.svg')"/>
                    <input type="text" @click="personError = false" :class="{ 'inputError':  personError}" :placeholder='$t("form.name")' v-model="person">
                </div>

                <div class="singleInput">
                    <img class="inputIcon" :src="require('@/assets/icons/form/email.svg')"/>
                    <input type="text" @click="emailError = false" :class="{ 'inputError':  emailError}" :placeholder='$t("form.email")' v-model="email">
                </div>

                <div class="singleInput">
                    <img class="inputIcon" :src="require('@/assets/icons/form/phone.svg')"/>
                    <input type="text" @click="phoneError = false" :class="{ 'inputError':  phoneError}" :placeholder='$t("form.mobile")' v-model="phoneNr">
                </div>

                <div class="singleInput">
                    <img class="inputIcon" :src="require('@/assets/icons/form/business.svg')"/>
                    <input type="text" @click="businessError = false" :class="{ 'inputError':  businessError}" :placeholder='$t("form.business")' v-model="business">
                </div>

                <textarea @click="letterError = false" :class="{ 'inputError':  letterError}" :placeholder='$t("form.message")' v-model="letter" name="" id="" cols="30" rows="10"></textarea>
                
                <button @click="sendInquiry()">{{ $t("buttons.sendMessage") }}</button>

                <reCaptcha class="reCaptcha" @recaptchaSuccess="recaptchaDone = true" />

                <div class="successMessage" v-if="inquirySent">
                    <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                    </svg>
                    <p>{{ $t("form.confirmationText") }}</p>
                </div>
            </div>
            <div class="bottomImage">
                <div class="imageWrapper">
                    <img class="image" :src="require('@/assets/images/IMG_4572-1.jpg')"/>
                    <div class="logoWrapper">
                        <img 
                            class="whiteLogo"
                            :src="require('@/assets/logo/whiteLogo.svg')" 
                            alt=""
                        >
                        <p>Pärnu Betoonelement</p>
                    </div>
                </div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d414.99740533340616!2d24.521544680937097!3d58.38571354350563!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46ecfd20c9bee8bd%3A0x8c8c2baea39e4bad!2sPilli%206%2C%20P%C3%A4rnu%2C%2080013%20P%C3%A4rnu%20maakond!5e0!3m2!1set!2see!4v1680525832878!5m2!1set!2see" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
        
    </div>
</template>

<script>
import reCaptcha from '../components/popups/reCaptcha.vue'

export default {
    name: 'aboutView',
    components: {
        reCaptcha
    },
    data() {
        return {
            inquirySent: false,

            errorMessage: null,
            recaptchaDone: null,

            person: null,
            personError: false,

            business: null,
            businessError: false,

            email: null,
            emailError: false,

            phoneNr: null,
            phoneError: false,

            letter: null,
            letterError: false
        }
    },  
    methods: {
        sendInquiry() {
            if(!this.person) {
                this.personError = true
                this.errorMessage = 'Nime, emaili ja täpsustava teksti sisestamine on kohustuslik!'
            }
            if(!this.email) {
                this.emailError = true
                this.errorMessage = 'Nime, emaili ja täpsustava teksti sisestamine on kohustuslik!'
            }
            if(!this.letter) {
                this.letterError = true
                this.errorMessage = 'Nime, emaili ja täpsustava teksti sisestamine on kohustuslik!'
            }
            if(!this.recaptchaDone) {
                this.errorMessage = 'Palun täitke reCAPTCHA kinnitus või proovige uuesti!'
            }
            if(!this.errorMessage) {
                fetch('https://parnubetoonelement.ee/mail', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "text": this.letter,
                        "name": this.person,
                        "company": this.business,
                        "phone": this.phoneNr,
                        "email": this.email
                    }),
                })
                .then((res) => {
                    if(res.ok) {
                        this.errorMessage = null
                        this.inquirySent = true
                        this.person = null
                        this.business = null
                        this.email = null
                        this.phoneNr = null
                        this.letter = null

                        setTimeout(() => {
                            this.inquirySent = false
                        }, 2000)
                    } else {
                        this.errorMessage = 'Midagi läks valesti, palun proovi uuesti!'
                    }
                })
            }
            if(this.errorMessage) {
                setTimeout(() => {
                    this.errorMessage = null
                }, 3000)
            }
        },
    },

    created() {
        this.$gtag('event', 'Lehe vaatamised', {
            Leheküljed: 'Kontakt',
        })
    }
}
</script>
<style scoped>
.content {
    position: relative;
    width: 1200px;
    z-index: 1000;
    margin: 0 auto;
    margin-top: 120px;
    padding-top: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.data {
    border-right: 1px solid rgb(245,245,245);
    text-align: start;
    width: 42%;
}
.nameWrapper {
    border-top: 1px solid rgb(230,230,230);
    padding-top: 10px;
    width: 90%;
    margin-bottom: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    margin-top: 2px;
}
.data h2 {
    margin-top: 10px;
    margin-bottom: 15px;
    color: #424A5F;
}
.data p {
    font-size: 19px;
    margin-bottom: 20px;
    color: rgb(62, 98, 114);
}
.data a {
    font-size: 19px;
    margin-bottom: 20px;
    color: rgb(62, 98, 114);
}
.data b {
    color: rgb(44, 70, 82);
}
.data p:first-child {
    font-size: 30px;
}
.single {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 10px;
    width: 90%;
}
span {
    color: steelblue;
    cursor: pointer;
    text-decoration: underline;
}
.single p {
    font-size: 21px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 5px;
    margin-top: 0px;
}
.single h2 {
    color: rgb(76, 88, 99);
}
.singleData {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 15px;
}
.singleData p {
    margin-top: auto;
    margin-bottom: auto;
    color: rgb(62, 98, 114);
}
.singleData a {
    margin-top: auto;
    margin-bottom: auto;
    color: rgb(62, 98, 114);
}
.singleData img {
    width: 20px;
    margin-left: 7px;
    margin-right: 24px;
}
.email {
    color: steelblue;
    text-decoration: underline;
    cursor: pointer;
}
.form {
    position: relative;
    margin-top: 15px;
    width: 55%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.singleInput {
    width: 48%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 10px;
}
.inputIcon {
    height: 30px;
    margin-top: 3px;
    margin-right: 10px;
    opacity: .9;
}
input {
    width: 90%;
    height: 35px;
    text-indent: 10px;
    border: 1px solid rgb(235,235,235);
    background: rgb(252,252,252);
    border-radius: 5px;
}
input:focus {
    outline: none;
    border: 1px solid rgb(220,220,220);
    background: white;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
textarea {
    width: 100%;
    border: 1px solid rgb(235,235,235);
    background: rgb(252,252,252);
    border-radius: 5px;
    text-indent: 10px;
    padding-top: 10px;
    resize: none;
}
textarea:focus {
    outline: none;
    border: 1px solid rgb(220,220,220);
    background: white;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
button {
    height: 45px;
    width: 250px;
    margin-top: 20px;
    border: none;
    border-radius: 5px;
    border: 1px solid #4D455D;
    background: #4D455D;
    color: white;
    border-radius: 100px;
    font-size: 20px;
}
button:hover {
    opacity: .9;
    cursor: pointer;
}
.bottomImage {
    position: relative;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 30px;
    margin-bottom: 50px;
}
.imageWrapper {
    position: relative;
    width: 42%;
    height: 250px;
    margin-right: 3%;
    -o-object-fit: cover;
       object-fit: cover;
}
.logoWrapper {
    position: absolute;
    width: 200px;
    bottom: 10px;
    left: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.logoWrapper p {
    position: absolute;
    top: 4px;
    bottom: 0;
    right: 0px;
    margin: auto;
    color: white;
    font-weight: bold;
}
.whiteLogo {
    width: 30px;
}
.image {
    width: 100%;
    height: 250px;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
}
iframe {
    width: 55%;
    height: 250px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
}
.error {
    position: absolute;
    z-index: 100;
    top: -100px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 0;
    border-radius: 0px 0px 10px 10px;
    width: 100%;
    background: #E76161;
    color: white;
    font-size: 18px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.errorActive {
    top: -60px;
    left: 0;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    padding-top: 8px;
    padding-bottom: 8px;
}
.inputError::-webkit-input-placeholder {
    color: #a11e34;
    -webkit-transition: .2s;
    transition: .2s;
}
.inputError::-moz-placeholder {
    color: #a11e34;
    -moz-transition: .2s;
    transition: .2s;
}
.inputError:-ms-input-placeholder {
    color: #a11e34;
    -ms-transition: .2s;
    transition: .2s;
}
.inputError::-ms-input-placeholder {
    color: #a11e34;
    -ms-transition: .2s;
    transition: .2s;
}
.inputError::placeholder {
    color: #a11e34;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.inputError {
    border: 1px solid #E76161;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}

.successMessage {
    background: rgba(255, 255, 255, 0.712);
    height: 300px;
    padding-top: 100px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 100;
}
.successMessage p {
    position: absolute;
    left: 0;
    right: 0;
    top: 270px;
    padding-left: 25px;
    padding-right: 25px;
    margin: auto;
    z-index: 1000;
    margin-top: 0px;
    font-size: 28px;
    color: #64C3A2;
}
.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #64C3A2;
    fill: none;
    -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
            animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
    position: absolute;
    left: 0;
    right: 0;
    top: -70px;
    bottom: 0;
    margin: auto;
    width: 175px;
    height: 175px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #64C3A2;
    stroke-miterlimit: 10;
    -webkit-box-shadow: inset 0px 0px 0px #64C3A2;
            box-shadow: inset 0px 0px 0px #64C3A2;
    -webkit-animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
            animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__check {
    -webkit-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
            animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

.reCaptcha {
    margin-top: 10px;
    margin-right: -2px;
}
@-webkit-keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}
@-webkit-keyframes scale {
    0%, 100% {
        -webkit-transform: none;
                transform: none;
    }
    50% {
        -webkit-transform: scale3d(1.1, 1.1, 1);
                transform: scale3d(1.1, 1.1, 1);
    }
}
@keyframes scale {
    0%, 100% {
        -webkit-transform: none;
                transform: none;
    }
    50% {
        -webkit-transform: scale3d(1.1, 1.1, 1);
                transform: scale3d(1.1, 1.1, 1);
    }
}
@-webkit-keyframes fill {
    100% {
        -webkit-box-shadow: inset 0px 0px 0px 63px #ffffff;
                box-shadow: inset 0px 0px 0px 63px #ffffff;
    }
}
@keyframes fill {
    100% {
        -webkit-box-shadow: inset 0px 0px 0px 63px #ffffff;
                box-shadow: inset 0px 0px 0px 63px #ffffff;
    }
}
@media (max-width: 1350px) {
    .content {
        width: 95%;
    }
}
@media (max-width: 850px) {
    .data {
        width: 100%;
        border-right: 0;
    }
    .form {
        width: 100%;
    }
    .logoWrapper {
        display: none;
    }
}
@media (max-width: 650px) {
    .content {
        margin-top: 75px;
        width: 90%;
    }
    .single {
        width: 100%;
    }
    .data h2 {
        font-size: 22px;
    }
    .bottomImage {
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    }
    .imageWrapper {
        width: 100%;
        margin-bottom: 15px;
        margin-right: 0%;
    }
    iframe {
        width: 100%;
    }
}
@media (max-width: 450px) {
    .singleInput {
        width: 100%;
    }
    button {
        width: 100%;
    }
}
</style>
