<template>
    <div class="productCard">
        <img 
            class="image"
            :src="require(`@/assets/images/products/${imgAssetDir}/${image}`)" 
            alt=""
        >
        <div class="contentContainer">
            <h2>{{name}}</h2>
            <p>{{category}}</p>
            <span class="text" v-html="shortDescription"></span>
        </div>
        <span class="elementFooter">
            <!-- <Button label="Küsi lisaks" style="background-color: white;" icon="pi pi-pen-to-square" severity="secondary" outlined class="w-full" /> -->
            <Button :label="$t('buttons.viewImages')" severity="info" icon="pi pi-image" class="w-full" />
        </span>
    </div>
</template>

<script>
import Button from 'primevue/button';

export default {
    name: 'productCard',
    components: {
        Button
    },
    props: {
        image: {},
        name: {},
        shortDescription: {},
        category: {},
        button: {},
        productUrl: {},
        imgAssetDir: {}
    },  
    data() {
        return {

        }
    },  
    methods: {
        
    },
}
</script>
<style scoped>
.productCard {
    position: relative;
    width: 325px;
    height: 420px;
    border-radius: 10px;
    /* -webkit-box-shadow: 0px 0px 10px 0px #e2e4e93d;
    box-shadow: 0px 0px 10px 0px #d8dbe4; */
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    background: rgb(255, 255, 255);
    overflow: hidden;
    border: 1px solid rgb(230,230,230);
}
.productCard:hover {
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.3);
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    cursor: pointer;
    background: white;
}

.image {
    width: 100%;
    height: 190px;
    -o-object-fit: cover;
       object-fit: cover;
    border-bottom: 1px solid rgb(180,180,180);
    -webkit-filter:brightness(.9);
            filter:brightness(.9);
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.productCard:hover .image {
    -webkit-filter: brightness(1);
            filter: brightness(1);
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.contentContainer {
    position: relative;
    width: 95%;
    margin: 0 auto;
    background: white;
    margin-top: -30px;
    z-index: 10;
    border-radius: 70px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 5px;
}
.productCard h2 {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;
    color: rgb(46, 87, 121);
    font-size: 22px;
    margin-bottom: 10px;
}
.contentContainer p {
    text-align: start;
    margin: 0;
    margin-bottom: 5px;
    color: rgb(150,150,150);
}
.text {
    text-align: start;
    color: rgb(100,100,100);
    display: -webkit-box; /* use the webkit box to define the number of lines */
    -webkit-line-clamp: 4; /* set the maximum number of lines */
    -webkit-box-orient: vertical; /* set the orientation of the box */
    -o-text-overflow: ellipsis;
       text-overflow: ellipsis; /* add the ellipsis at the end of the text */
    overflow: hidden; /* hide any overflowing text */
}
.elementFooter {
    position: absolute;
    left: 0;
    bottom: 25px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    background: #f0f7fd;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.elementFooter span {
    font-size: 20px;
    color: #424A5F;
    font-weight: bold;
}
.elementFooter button {
    width: 100%;
}
@media (max-width: 440px) {
    .productCard {
        width: 90%;
        height: 430px;
    }
}
</style>