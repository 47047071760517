<template>
    <div class="mainContainer">
        <div class="contentWrapper">
            <div class="content" ref="parentContainer">
                <div class="product" v-if="productData">
                    <div class="productMenuWrapper">
                        <productMenu class="productMenu" @openProduct="openProduct" :productData="productData" :productList="productList" />
                    </div>
                    <singleProduct @openContactPopup="$emit('openContactPopup')" @openProduct="openProduct" :productList="productList" :productData="productData" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import tr from "@/i18n/translation"
import singleProduct from "@/components/product/singleProduct.vue"
import productMenu from "@/components/product/productMenu.vue"

export default {
    name: 'productView',
    components: {
        singleProduct,
        productMenu
    },
    data() {
        return {
            productUrl: this.$route.params.productUrl.toLowerCase(),
            productList: null,
            productData: null,
            textSections: null,
            activeIndex: 0
        }
    },
    watch: {
        localeChanged() {
            this.getLocaleData()
        }
    },
    methods: {
        getLocaleData() {
            const promise = tr.productList()
            promise.then((result) => {
                this.productList = result.productList
                this.textSections = result.textSections
                this.loadProduct()
            })
        },

        openProduct(product, index) {
            this.$gtag('event', 'Tooted', {
                Toodete_vaatamised: `Avati toode - ${product.name}`,
            })
            this.activeIndex = index
            this.productData = product
            const locale = tr.currentLocale
            const url = this.$t("router.products")
            window.history.pushState({}, '', '/'+locale+'/'+url+'/'+this.productData.productUrl);
        },

        loadProduct() {
            for(const element of this.productList) {
                if(element.productUrl == this.productUrl) {
                    this.productData = element
                }
            }
            if(this.productData == null) {
                this.productData = this.productList[0]
                const locale = tr.currentLocale
                const url = this.$t("router.products")
                window.history.pushState({}, '', '/'+locale+'/'+url+'/'+this.productList[0].productUrl);
            } 
        }
    },

    created() {
        this.getLocaleData()
        this.$gtag('event', 'Lehe vaatamised', {
            Leheküljed: 'Tooted',
        })
    }
}
</script>
<style scoped>
.mainContainer {
    position: relative;
    margin: 0px auto;
}
.contentWrapper {
    position: relative;
    margin-top: 190px;
    padding-top: 30px;
    background: white;
}
.content {
    position: relative;
    width: 1200px;
    margin: 0 auto;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.product {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
}
.productMenu {
    position: sticky;
    margin-right: 50px;
}
.productMenuWrapper {
    position: relative;
}
@media (max-width: 1250px) {
    .mainContainer {
        position: relative;
        width: 95%;
    }
    .content {
        position: relative;
        width: 100%;
    }
}
@media (max-width: 915px) {
    .productMenuWrapper {
        display: none;
    }
}
@media (max-width: 650px) {
    .contentWrapper {
        margin-top: 150px;
    }
}
</style>
