import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from "./i18n"

import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura'
import 'primeicons/primeicons.css';   


const app = createApp(App)
app.use(router)
app.use(i18n)
app.use(PrimeVue, {
    theme: {
        preset: Aura
    }
});


app.config.globalProperties.isLocaleChanged = 0

window.dataLayer = window.dataLayer || [];
function gtag(){window.dataLayer.push(arguments);}
gtag('js', new Date())

gtag('config', 'G-MNFHCDXJ8K', {
    send_page_view: false
})
app.config.globalProperties.$gtag = gtag

var cookieConsent = localStorage.getItem('cookieConsent')
if(cookieConsent) {
    if(cookieConsent == 'declined') {
        window['ga-disable-G-MNFHCDXJ8K'] = true
    }
    if(cookieConsent == 'accepted') {
        window['ga-disable-G-MNFHCDXJ8K'] = false
    }
} else {
    window['ga-disable-G-MNFHCDXJ8K'] = true
}

app.mount('#app')

