<template>
    <div class="menu" ref="stickyElement">
        <div class="title">
            <img 
                class="logoImgWhite"
                :src="require('@/assets/logo/whiteLogo.svg')" 
                alt=""
            >
            <h2>{{ $t("products.menuTitle") }}</h2>
        </div>
        <div 
            class="singleProduct"
            :class="{ 'singleProductActive': activeIndex == index }"
            v-for="(element, index) of productList" :key="element.id"
            @click="openProduct(element, index)"
        >
            <img class="icon" :src="require('@/assets/icons/iconArrow.svg')"/>
            <img class="whiteIcon" :src="require('@/assets/icons/iconArrowWhite.svg')"/>
            <span>{{ element.name }}</span>
        </div>
        <div style="text-align: center">
            <img 
                class="logoImg"
                :src="require('@/assets/logo/blackLogo.svg')" 
                alt=""
            >
        </div>
    </div>
</template>

<script>
export default {
    name: 'productMenu',
    props: {
        productList: {},
        productData: {}
    },  
    data() {
        return {
            activeIndex: 0
        }
    },  
    methods: {
        openProduct(product, index) {
            this.activeIndex = index
            this.$emit('openProduct', product, index)
        }
    },
    created() {
        for(const element of this.productList) {
            if(element.productUrl == this.productData.productUrl) {
                this.activeIndex = this.productList.indexOf(element)
            }
        }
    }
}
</script>
<style scoped>
.menu {
    overflow: hidden;
    width: 320px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.2);
    border-radius: 10px;
    text-align: start;
    padding-bottom: 5px;
    background: rgb(252,252,252);
}
.title {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-top: 25px;
    padding-left: 25px;
    padding-bottom: 10px;
    margin-bottom: 15px;
    background: rgb(66, 74, 95);
}
.menu h2 {
    color: white;
}
.logoImgWhite {
    margin-top: -10px;
    margin-right: 10px;
    width: 42px;
}
.singleProduct {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    padding-left: 19px;
    padding-top: 10px;
    padding-bottom: 10px;
    cursor: pointer;
    font-weight: bold;
    font-size: 17px;
    color: rgb(50,50,50);
    width: 80%;
    border-radius: 100px;
    margin: 0 auto;
    margin-bottom: 10px;
    z-index: 10;
    border: 1px solid rgba(43, 79, 119, 0);
}
.singleProduct:hover {
    border: 1px solid rgba(43, 79, 119, 0.2);
}
.singleProductActive {
    background: rgb(43, 79, 119);
    color: white;
    font-weight: bold;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.singleProductActive .icon {
    display: none;
}
.singleProduct .whiteIcon {
    display: none;
}
.singleProductActive .whiteIcon {
    display: initial;
}
.icon {
    margin-right: 15px;
}
.whiteIcon {
    margin-right: 15px;
}
.logoImg {
    margin-top: 25px;
    margin-bottom: 30px;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    width: 270px;
    opacity: 1;
}
.singleData {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 30px;
    margin-bottom: 10px;
}
.singleData p {
    font-size: 18px;
    color: #4D455D;
}
.inputIcon {
    width: 25px;
    margin-right: 15px;
    opacity: .9;
}
button {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 13px;
    margin: auto;
    height: 35px;
    width: 292px;
    padding-bottom: 2px;
    font-size: 18px;
    border: none;
    background: #f3a228;
    border-radius: 5px;
    color: white;
    cursor: pointer;
}
button:hover {
    opacity: .9;
}
@media (max-width: 1050px) {
    .menu {
        width: 320px;
    }
    button {
        width: 250px;
        bottom: 15px;
    }
}
</style>