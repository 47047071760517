<template>
    <div class="wrapper">
        <div class="container">
            <div class="titleContainer">
                <h2 class="title">{{ $t("privacyPolicyView.header") }}</h2>
                <div class="roundedDivider"></div>
            </div>
            <div class="paragraph" v-for="element of privacyPolicyList" :key="element.id">
                <span>{{ element.title }}</span>
                <p>{{ element.text }}</p>
            </div>
            <br>
            <p class="updatedDate">{{ $t("privacyPolicyUpdated.title") }}: {{ $t("privacyPolicyUpdated.date") }}</p>
        </div>
    </div>
</template>

<script>
import tr from "@/i18n/translation"

export default {
    name: 'privacyPolicy',
    data() {
        return {
            privacyPolicyList: []
        }
    },  
    watch: {
        localeChanged() {
            this.getLocaleData()
        }
    },
    methods: {
        getLocaleData() {
            const promise = tr.productList()
            promise.then((result) => {
                this.privacyPolicyList = result.privacyPolicy
            })
        },
    },

    created() {
        this.getLocaleData()
        this.$gtag('event', 'Lehe vaatamised', {
            Leheküljed: 'Privaatsustingimused',
        })
    }
}
</script>
<style scoped>
.wrapper {
    padding-top: 75px;
    padding-bottom: 50px;
    background: rgb(250,250,250);
}
.container {
    padding: 50px 0px 30px 150px;
    width: 1050px;
    margin: 0px auto;
    text-align: start;
    border: 1px solid rgb(240,240,240);
    border-radius: 3px;
    background: white;
}
.title {
    font-size: 40px;
}
.roundedDivider {
    width: 120px;
    height: 4px;
    border-radius: 4px;
    margin-top: 15px;
    margin-bottom: 50px;
    background: #4A154B;
}
.paragraph {
    margin-top: 50px;
}
.paragraph span {
    font-size: 24px;
    font-weight: bold;
}
.paragraph p {
    margin-top: 10px;
    width: 60%;
    color: rgb(50,50,50);
}
.updatedDate {
    margin-top: 35px;
    text-align: end;
    padding-right: 50px;
    font-weight: bold;
}
@media (max-width: 1350px) {
    .container {
        width: 90%;
    }
}
@media (max-width: 1100px) {
    .wrapper {
        padding-top: 25px;
        background: white;
    }
    .container {
        width: 95%;
        padding: 0;
        border: none;
    }
    .updatedDate {
        padding-right: 0;
    }
    .paragraph p {
        width: 70%;
    }
}
@media (max-width: 650px) {
    .wrapper {
        padding-top: 15px;
    }
    .title {
        font-size: 30px;
    }
    .paragraph {
        margin-top: 35px;
    }
    .paragraph p {
        width: 95%;
    }
    .container {
        width: 85%;
    }
    .roundedDivider {
        width: 120px;
        height: 3px;
        border-radius: 40px;
        margin-top: 10px;
        margin-bottom: 35px;
        background: #4A154B;
    }
}
</style>