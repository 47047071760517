<template>
    <div class="menu">
        <div class="horizontalListContainer">
            <div class="arrowBackground" :class="{ 'arrowBackgroundHidden': scrollPos < 50 }">
                <div class="arrow" @click="scrollHorizontally('left')">
                    <img 
                        class="downArrow"
                        :src="require('@/assets/icons/smallArrow.svg')" 
                    >
                </div>
            </div>
            <div 
                class="horizontalList" 
                ref="horizontalList"
            >
                <li 
                    class="catList"
                    v-for="(element, index) of productList" :key="element.id"
                    :class="{ 'catListActive': element.name == activeCat.name }"
                    @click="openProduct(element, index)"
                >   
                    <p>{{element.name}}</p>
                    
                </li>
            </div>
            <div class="arrowBackground" :class="{ 'arrowBackgroundHidden': maxScrollLeft == scrollPos }">
                <div class="arrow" @click="scrollHorizontally('right')">
                    <img 
                        class="downArrow"
                        :src="require('@/assets/icons/smallArrow.svg')" 
                    >
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'productMenuMobile',
    props: {
        productList: {}
    },  
    data() {
        return {
            activeCat: null,
            activeIndex: 0,
            scrollPos: 0,
            maxScrollLeft: null
        }
    },  
    methods: {
        scrollHorizontally(direction) {
            const menu = this.$refs.horizontalList
            this.maxScrollLeft = menu.scrollWidth - menu.clientWidth

            if(direction == 'left' && this.scrollPos != 0) {
                menu.scrollTo({
                    left: this.scrollPos -= 500,
                    behavior: 'smooth',
                })
            }
            if(direction == 'right' && this.scrollPos < this.maxScrollLeft) {
                menu.scrollTo({
                    left: this.scrollPos += 500,
                    behavior: 'smooth',
                })
            }
            if(this.scrollPos > this.maxScrollLeft) {
                this.scrollPos = this.maxScrollLeft
            }
        },

        actionScroll() {
            const scroll = this.$refs.horizontalList
            scroll.addEventListener('scroll', () => {
                const scrollPosition = this.$refs.horizontalList.scrollLeft;
                this.scrollPos = scrollPosition
                this.maxScrollLeft = scroll.scrollWidth - scroll.clientWidth
            }, false)
        },

        openProduct(product, index) {
            this.activeIndex = index
            this.activeCat = product
            this.$emit('openProduct', product, index)
        }
    },
    created() {
        this.activeCat = this.productList[0]
    },

    mounted() {
        this.actionScroll()
    }
}
</script>
<style scoped>
.horizontalListContainer {
    position: relative;
    margin-top: 20px;
}
.horizontalList {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 50px;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    margin: 5px auto;
    margin-left: 0px;
    margin-bottom: 0px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
}
.arrowBackground:first-child {
    left: 0;
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
}
.arrowBackground {
    position: absolute;
    top: 2px;
    right: 0;
    z-index: 1000;
    width: 40px;
    height: 41px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    background: -o-linear-gradient(right, rgb(250, 250, 250) 15px, rgba(255, 255, 255, 0));
    background: linear-gradient(to left, rgb(250, 250, 250) 15px, rgba(255, 255, 255, 0));
    background-image: -o-linear-gradient(right, rgb(250, 250, 250) 15px, rgba(255, 255, 255, 0));
    background-image: linear-gradient(to left, rgb(250, 250, 250) 15px, rgba(255, 255, 255, 0))
}
.arrowBackgroundHidden {
    opacity: 0;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    z-index: -1;
}
.arrow {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    bottom: 0;
    left: 12px;
    margin: auto;
    z-index: 1000;
    width: 26px;
    height: 26px;
    background: white;
    border: 1px solid silver;
    border-radius: 100px;
}
.arrow:hover {
    width: 30px;
    height: 30px;
}
.arrow img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0px;
    width: 16px;
    height: 16px;
    margin: auto;
    -webkit-transform: rotate(-0deg);
        -ms-transform: rotate(-0deg);
            transform: rotate(-0deg);
}
.arrow:hover img {
    width: 20px;
    height: 20px;
}
.catList {
    position: relative;
    cursor: pointer;
    color: rgb(25, 25, 25);
    background-color: rgba(240, 240, 240);
    border-radius: 8px;
    font-family: 'DM sans', sans-serif;
    font-weight: 400;
    font-size: 17px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    height: 30px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 9px;
    padding-bottom: 1px;
    margin-top: 2px;
    margin-left: 5px;
    margin-right: 5px;
    white-space: nowrap;
    text-align: center;
    overflow: hidden;
}
.catList:hover {
    color: rgb(255, 255, 255);
    background-color: rgba(75, 75, 75);
}
.catList:hover .illustration {
    opacity: .3;
    -webkit-filter: grayscale(0);
            filter: grayscale(0);
}
.catList p {
    position: relative;
    z-index: 10;
    margin: 0;
}
.catList:first-child {
    margin-left: 0px;
}
.catList:last-child {
    margin-right: 0px;
}
.catListActive {
    color: #ffffff;
    background: #363636;
    position: relative;
    cursor: pointer;
    border-radius: 8px;
    font-family: 'DM sans', sans-serif;
    font-weight: 400;
    font-size: 17px;
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    height: 30px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 9px;
    padding-bottom: 2px;
    margin-top: 2px;
    margin-left: 5px;
    margin-right: 5px;
    white-space: nowrap;
    text-align: center;
    overflow: hidden;
}
.catListActive img {
    opacity: 0;
}
.catListActive:hover .illustration {
    opacity: .15;
}
.catListActive:hover {
    color: white;
}
@media (max-width: 505px) {
    .catList {
        font-size: 15px;
        height: 28px;
        padding-left: 6px;
        padding-right: 6px;
        padding-bottom: 0px;
        margin-left: 4px;
        margin-right: 4px;
    }
    .catListActive {
        font-size: 15px;
        height: 28px;
        padding-left: 6px;
        padding-right: 6px;
        padding-bottom: 1px;
        margin-left: 4px;
        margin-right: 4px;
    }
    .arrowBackground {
        height: 38px;
        top: 2px;
    }
}
</style>