<template>
    <div class="popupWrapperActive">
        <div class="contentBody">
            <div class="imagesContainer">
                <div class="images">
                    <div class="mainCarousel">
                        <img 
                            class="arrow"
                            @click="currentSlide --"
                            :src="require('@/assets/icons/smallArrow.svg')" 
                            alt=""
                        >
                        <img 
                            class="arrow"
                            @click="currentSlide ++"
                            :src="require('@/assets/icons/smallArrow.svg')" 
                            alt=""
                        >
                        <Carousel id="gallery" :wrap-around="true" v-model="currentSlide">
                            <Slide class="slide" v-for="(slide, index) in carouselImages" :key="slide" @click="openImage(index)">
                                <img 
                                    class="singleSlide"
                                    :src="require(`@/assets/images/products/${productData.imgAssetDir}/${slide.name}`)" 
                                    alt=""
                                >
                                <span class="openGallery">
                                    <img 
                                        :src="require('@/assets/icons/galleryIcon.svg')" 
                                        alt=""
                                    >
                                    {{ $t("products.imageHoverTitle") }}
                                </span>
                            </Slide>
                        </Carousel>
                    </div>
                    <div class="singleImages">
                        <img 
                            class="image"
                            @click="openImage(index+1)"
                            v-for="(element, index) in images" :key="element.id"
                            :src="element.largeURL" 
                            alt=""
                        >
                    </div>
                </div>
                <productMenuMobile 
                    class="productMenuMobile" 
                    @openProduct="openProduct" 
                    :productList="productList"
                    v-if="productList"
                />
                <div class="body">
                    <div class="text">
                        <div class="wrapper">
                            <h2>{{ productData.name }}</h2>
                            <div class="roundedDividerMobile"></div>
                        </div>
                        <div class="roundedDivider"></div>
                        <p class="description" v-html="productData.description"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel'
import productMenuMobile from "@/components/product/productMenuMobile.vue"
import 'photoswipe/style.css';
import PhotoSwipe from 'photoswipe';

export default {
    name: 'singleProduct',
    components: {
        Carousel,
        Slide,
        productMenuMobile,
    },
    props: {
        productList: {},
        productData: {},
    },
    watch: {
        productData() {
            this.setImages()
        }
    },
    data() {
        return {
            currentSlide: 0,
            images: [],
            carouselImages: [],
            componentKey: 0
        }
    },  
    methods: {
        openProduct(product, index) {
            this.$emit('openProduct', product, index)
        },

        openImage(index) {
            let sourceImages = []
            for(const element of this.productData.refImages) {
                sourceImages.push({
                    src: require(`@/assets/images/products/${this.productData.imgAssetDir}/${element.name}`),
                    width: element.width,
                    height: element.height
                })
            }

            if(this.productData.refImages.length == 1) {
                sourceImages.push({
                    src: require(`@/assets/images/products/substitute1.jpg`),
                    width: 480,
                    height: 640,
                })
                sourceImages.push({
                    src: require(`@/assets/images/products/substitute2.jpg`),
                    width: 480,
                    height: 640,
                })
            }

            if(this.productData.refImages.length == 2) {
                sourceImages.push({
                    src: require(`@/assets/images/products/substitute1.jpg`),
                    width: 480,
                    height: 640,
                })
            }

            const options = {
                dataSource: sourceImages,
                showHideAnimationType: 'none'
            };

            options.index = index;
            const pswp = new PhotoSwipe(options);
            pswp.init();
        },

        setImages() {
            this.currentSlide = 0
            this.carouselImages = []
            this.images = []
            for(const element of this.productData.refImages) {
                this.images.push({
                    name: element.name,
                    largeURL: require(`@/assets/images/products/${this.productData.imgAssetDir}/${element.name}`),
                    thumbnailURL: require(`@/assets/images/products/${this.productData.imgAssetDir}/${element.name}`),
                    width: element.width,
                    height: element.height,
                })
            }
            if(this.images.length == 1) {
                this.images = []
                this.images.push({
                    largeURL: require(`@/assets/images/products/substitute1.jpg`),
                    thumbnailURL: require(`@/assets/images/products/substitute1.jpg`),
                    width: 480,
                    height: 640,
                })
                this.images.push({
                    largeURL: require(`@/assets/images/products/substitute2.jpg`),
                    thumbnailURL: require(`@/assets/images/products/substitute2.jpg`),
                    width: 480,
                    height: 640,
                })
                this.carouselImages = this.productData.refImages
                this.carouselImages.push({
                    name: 'substitute1.jpg',
                    width: 480,
                    height: 640,
                })
                this.carouselImages.push({
                    name: 'substitute2.jpg',
                    width: 480,
                    height: 640,
                })
            } 

            else if (this.images.length == 2) {
                this.images.push({
                    largeURL: require(`@/assets/images/products/substitute1.jpg`),
                    thumbnailURL: require(`@/assets/images/products/substitute1.jpg`),
                    width: 480,
                    height: 640,
                })
                this.images = this.images.slice(1)

                this.carouselImages = this.productData.refImages
                this.carouselImages.push({
                    name: 'substitute1.jpg',
                    width: 480,
                    height: 640,
                })
            } 
            
            else {
                this.images = []
                this.images.push({
                    name: this.productData.refImages[1].name,
                    largeURL: require(`@/assets/images/products/${this.productData.imgAssetDir}/${this.productData.refImages[1].name}`),
                    thumbnailURL: require(`@/assets/images/products/${this.productData.imgAssetDir}/${this.productData.refImages[1].name}`),
                    width: this.productData.refImages[1].width,
                    height: this.productData.refImages[1].height,
                })
                this.images.push({
                    name: this.productData.refImages[1].name,
                    largeURL: require(`@/assets/images/products/${this.productData.imgAssetDir}/${this.productData.refImages[2].name}`),
                    thumbnailURL: require(`@/assets/images/products/${this.productData.imgAssetDir}/${this.productData.refImages[2].name}`),
                    width: this.productData.refImages[2].width,
                    height: this.productData.refImages[2].height,
                })

                this.carouselImages = this.productData.refImages
            }
            
            this.componentKey += 1;
        }
    },
    created() {
        this.setImages()
    }
}
</script>
<style scoped>
.popupWrapperActive {
    position: relative;
    width: 100%;
    height: 100%;
    background: rgb(255,255,255);
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    text-align: start;
}
.contentBody {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.imagesContainer {
    width: 825px;
}
.singleSlide {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 10px;
}
.openGallery {
    position: absolute;
    bottom: -60px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    color: white;
    background: rgb(48, 43, 58);
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(77,69,93,0)), to(rgb(48, 43, 58)));
    background: -o-linear-gradient(top, rgba(77,69,93,0) 0%, rgb(48, 43, 58) 100%);
    background: linear-gradient(180deg, rgba(77,69,93,0) 0%, rgb(48, 43, 58) 100%);
    width: 100%;
    padding: 40px 0px 20px 0px;
    font-weight: bold;
    font-size: 20px;
}
.openGallery img {
    width: 30px;
    position: absolute;
    left: 55px;
}
.slide:hover > .openGallery {
    bottom: 0;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.singleSlide:hover {
    cursor: pointer;
}
.slide {
    position: relative;
    overflow: hidden;
    height: 460px;
    width: 451px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid rgb(220,220,220);
}
.images {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.mainCarousel {
    position: relative;
    width: 505px;
    height: 460px;
    border-radius: 10px;
}
.arrow {
    position: absolute;
    z-index: 10;
    left: -17px;
    top: 0;
    bottom: 0px;
    margin: auto;
    width: 30px;
    height: 30px;
    padding: 6px;
    cursor: pointer;
    background: rgb(255,255,255);
    border: 1px solid rgb(230,230,230);
    /* -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,.3);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,.3); */
    border-radius: 100%;
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
}
.arrow:hover {
    -webkit-box-shadow: 0px 0px 4px 0px rgba(0,0,0,.3);
    box-shadow: 0px 0px 4px 0px rgba(0,0,0,.3);
    border: 1px solid transparent;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.arrow:nth-child(2) {
    right: -17px;
    left: auto;
    -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
            transform: rotate(0deg);
}
.singleImages {
    width: 300px;
    display: block;
}

.singleImages img {
    width: 300px;
    height: 220px;
    margin-bottom: 12px;
    border-radius: 10px;
    border: 1px solid rgb(220,220,220);
    cursor: pointer;
    -o-object-fit: cover;
       object-fit: cover;
}
.singleImages a {
    border: 1px solid red;
}
.body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding-bottom: 100px;
}
.text {
    width: 800px;
}
.wrapper {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 13px;
}
.text h2 {
    margin: 0;
    text-align: start;
    color: #4D455D;
    font-size: 2rem;
}
.dot {
    width: 5px;
    height: 5px;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 15px;
    margin-right: 15px;
    border-radius: 100%;
    background: rgb(50,50,50);
}
.categoryName {
    text-align: center;
    font-size: 18px;
    color: gray;
    margin: 0;
    margin-top: auto;
    margin-bottom: auto;
}
.roundedDivider {
    width: 150px;
    height: 3px;
    border-radius: 104px;
    margin-top: 12px;
    margin-bottom: 50px;
    margin-left: 3px;
    background: #4A154B;
}
.description {
    padding-left: 5px;
    width: 75%;
}
.roundedDividerMobile {
    width: 150px;
    height: 3px;
    border-radius: 104px;
    margin-top: 12px;
    margin-bottom: 50px;
    margin-left: 3px;
    background: #4A154B;
    display: none;
}
.productMenuMobile {
    display: none;
}

@media (max-width: 1250px) {
    .openGallery {
        display: none;
    }
    .imagesContainer {
        margin-right: 0px;
        margin-left: 0px;
        width: 580px;
    }
    .singleSlide {
        width: 600px;
    }
    .slide {
        height: 420px;
        width: 100%;
    }
    .mainCarousel {
        width: 60%;
        height: 420px;
        margin-right: 20px;
    }
    .text {
        width: 600px;
    }
}
@media (max-width: 1100px) {
    .singleImages {
        display: none;
    }
    .imagesContainer {
        margin-right: 0px;
        margin-left: 0px;
        width: 100%;
    }
    .mainCarousel {
        width: 50%;
    }
}

@media (max-width: 915px) {
    .contentBody {
        display: contents;
    }
    .text {
        width: 90%;
    }
    .mainCarousel {
        width: 97%;
    }
    .productMenuMobile {
        display: block;
    }
}
@media (max-width: 500px) {
    .imagesContainer {
        margin-right: 5px;
        margin-left: 5px;
    }
    .arrow {
        left: 10px;
    }
    .arrow:nth-child(2) {
        right: 10px;
    }
    .mainCarousel {
        height: 320px;
    }
    .slide {
        height: 320px;
    }
    .wrapper {
        display: block;
        margin-bottom: 25px;
    }
    .wrapper h2 {
        margin-top: 15px;
        margin-bottom: 5px;
    }
    .roundedDivider {
        display: none;
    }
    .roundedDividerMobile {
        display: block;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .dot {
        margin-top: 10px;
        margin-bottom: 0;
        margin-left: 5px;
        margin-right: 10px;
    }
    .categoryName {
        text-align: center;
        font-size: 18px;
        margin: 0;
        margin-top: auto;
        margin-bottom: auto;
    }
    .body {
        padding-bottom: 50px;
    }
    .description {width: 100%;}
}
</style>